import de from "@Resources/translations/de.json";
import rm from "@Resources/translations/rm.json";

export const languages = {
  de: de,
  rm: rm,
};

const DEFAULT_LANGUAGE = "de";
type LanguageKey = keyof typeof languages;

export const getTranslations = (language = DEFAULT_LANGUAGE) => {
  return languages[language as LanguageKey];
};
