import { Error } from "@Components/forecast/Error";
import { EMBED_ROOT_CLASS_SELECTOR } from "@Constants/embed";
import { ForecastAppContextProvider } from "@Context/ForecastAppContext/ForecastAppContext";
import { State } from "@Context/ForecastAppContext/forecastAppReducer";
import { TranslationsProvider } from "@Context/TranslationContext/TranslationContext";
import { Environment } from "@Models/Environment";
import * as Sentry from "@sentry/react";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";

import { initSentry } from "@Utils/sentry";
import "./App.css";

const ForecastApp = lazy(() =>
  import("./ForecastApp").then((module) => ({ default: module.ForecastApp })),
);
const SearchApp = lazy(() =>
  import("./SearchApp").then((module) => ({ default: module.SearchApp })),
);

const embeds = Array.from(
  document.querySelectorAll(`.${EMBED_ROOT_CLASS_SELECTOR}`),
);
embeds
  .map((embed) => embed as HTMLElement)
  .forEach((embed) => {
    // prevent duplicated initialization by multiple scripts on the same page
    if (embed.childElementCount > 0) {
      return;
    }

    const initialState: Partial<State> = {};

    // make root element stretch entire width
    embed.style.width = "100%";

    const devMode = Object.prototype.hasOwnProperty.call(
      embed.dataset,
      "devmode",
    );

    const searchOnly = Object.prototype.hasOwnProperty.call(
      embed.dataset,
      "searchOnly",
    );

    let env: Environment = "prod";
    if (embed.dataset.env && embed.dataset.env !== "") {
      env = embed.dataset.env.toLowerCase().trim() as Environment;
    }

    // Only read Sentry DSN from environment when in devMode, aka not embedded.
    // In the case of embedded, it needs to be set via data attribute.
    let sentryDsn = devMode ? import.meta.env.VITE_FORECAST_SENTRY_DSN : "";

    // If Sentry DSN is set via data attribute, use that instead
    // eslint-disable-next-line no-prototype-builtins
    if (embed.dataset.hasOwnProperty("sentryDsn")) {
      sentryDsn = embed.dataset.sentryDsn;
    }

    const sentrySampleRate: number = +(embed.dataset.sentrySampleRate || 1.0);
    const sentryRelease = import.meta.env.VITE_RELEASE || "0000000";

    if (sentryDsn) {
      initSentry(sentryDsn, sentrySampleRate, sentryRelease, env, devMode);
    }

    let geolcationIdFallback = embed.dataset.geolocationId || null;

    // Unfortunately, when the VITE_GEOLOCATION_ID env variable is not set, in HTML, it is left-as is (the string "%VITE_GEOLOCATION_ID%"), as opposed
    // to JS where it is replaced with undefined. This guard here ensures that no invalid API request to /forecastpoint/%VITE_GEOLOCATION_ID% is made.
    // See: https://vitejs.dev/guide/env-and-mode#html-env-replacement
    if (geolcationIdFallback === "%VITE_GEOLOCATION_ID%") {
      geolcationIdFallback = null;
    }

    ReactDOM.createRoot(embed).render(
      <React.StrictMode>
        <TranslationsProvider
          language={embed.dataset.language?.toLowerCase().trim()}
          devMode={devMode}>
          <Sentry.ErrorBoundary fallback={<Error />}>
            <ForecastAppContextProvider value={initialState}>
              <div className="m-h-max m-bg-blue-600 m-font-sans m-text-neutral-white dark:m-bg-blue-800">
                <Suspense fallback={null}>
                  {searchOnly ? (
                    <SearchApp devMode={devMode} />
                  ) : (
                    <ForecastApp
                      devMode={devMode}
                      geolcationIdFallback={geolcationIdFallback}
                    />
                  )}
                </Suspense>
              </div>
            </ForecastAppContextProvider>
          </Sentry.ErrorBoundary>
        </TranslationsProvider>
      </React.StrictMode>,
    );
  });
