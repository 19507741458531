import {
  DEFAULT_LANGUAGE,
  LOCALES_TO_LANGUAGE,
} from "@Context/TranslationContext/TranslationContext";
import { DayForecastInterval } from "@MeteoApi/models/DayForecastInterval";
import { ForecastPointWeek } from "@MeteoApi/models/ForecastPointWeek";
import { getTranslations } from "@Utils/translations";
import { isValid, parse } from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const getEffectiveIntlLocale = (locale: string) => {
  // As Romansh is not supported by Intl, we use de-CH
  // In case we need translations, like for the weekdays, we override them manually there.
  if (locale === "rm") {
    return "de-CH";
  }

  return locale;
};

export const toUtcDate = (timestamp?: string): Date => {
  const ts = timestamp || new Date().toISOString();

  const localDate = new Date(ts);
  const utcDate = new Date(localDate.toISOString());

  return utcDate;
};

export const formatAsIsoStyleDate = (timestamp: string): string => {
  return timestamp.substring(0, Math.min(timestamp.length, 10));
};

export const getCurrentDay = (
  forecastPointWeek?: ForecastPointWeek,
  activeDate?: string,
) => {
  if (
    !forecastPointWeek ||
    !forecastPointWeek?.days ||
    forecastPointWeek.days.length === 0
  ) {
    return {
      date_time: "2024-01-01T00:00:00+00:00",
      RRR_MM: 0,
      PROBPCP_PERCENT: 0,
      SUN_H: 0,
      FF_KMH: 0,
      FX_KMH: 0,
      SUNRISE: "2024-01-01T00:00:00+00:00",
      SUNSET: "2024-01-01T00:00:00+00:00",
    };
  }

  if (activeDate) {
    const days = forecastPointWeek.days.filter(
      (interval: DayForecastInterval) =>
        interval.date_time.startsWith(activeDate),
    );
    return days[0] ?? forecastPointWeek.days[0];
  } else {
    return forecastPointWeek.days[0];
  }
};

export const getFormattedDate = (
  dateTime: string,
  timezone: string,
  locale: string,
) => {
  const parsedDate = toUtcDate(dateTime);
  const zonedDate = toZonedTime(parsedDate, timezone);
  const date = zonedDate.toLocaleDateString(getEffectiveIntlLocale(locale), {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
  return date;
};

export const getFormattedTimestring = (
  hours: string,
  timezone: string,
  locale: string,
) =>
  toZonedTime(hours, timezone).toLocaleTimeString(
    getEffectiveIntlLocale(locale),
    {
      hour: "2-digit",
      minute: "2-digit",
    },
  );

export const formatAsWeekday = (
  timestamp: string,
  timezone: string,
  locale?: string,
): string => {
  const effectiveLocale = locale ?? "de-CH";
  const translations = getTranslations(
    LOCALES_TO_LANGUAGE[effectiveLocale] ?? DEFAULT_LANGUAGE,
  );

  const utcDate = toUtcDate(timestamp);

  const zonedDate = toZonedTime(utcDate, timezone);
  const weekdayIndex = zonedDate.getDay();

  return translations.weekday[weekdayIndex];
};

export const isValidIsoDate = (date: string): boolean => {
  const parsedDate = parse(date, "yyyy-MM-dd", new Date());
  return isValid(parsedDate);
};
