import { ContentContainer } from "@Components/forecast/ContentContainer";
import { useTranslations } from "@Context/TranslationContext/TranslationContext";

export const Error = () => {
  const { t } = useTranslations();

  return (
    <ContentContainer>
      <div className="m-flex m-flex-col m-items-center m-justify-center m-py-6 m-tracking-0.2 m-text-neutral-white m-antialiased">
        <h2 className="m-text-2xl m-font-medium md:m-text-4xl">
          {t("forecast.error.title")}
        </h2>
        <p className="m-font-normal m-tracking-0.4">
          {t("forecast.error.message")}
        </p>
      </div>
    </ContentContainer>
  );
};
