import { getTranslations, languages } from "@Utils/translations";
import { ReactNode, createContext, useContext, useState } from "react";

export const GERMAN = "de";
export const ROMANSH = "rm";
export const DEFAULT_LANGUAGE = GERMAN;

const LOCALES: { [key: string]: string } = {
  [GERMAN]: "de-CH",
  [ROMANSH]: "rm",
};

export const LOCALES_TO_LANGUAGE: { [key: string]: string } = {
  "de-CH": GERMAN,
  rm: ROMANSH,
};

export const LANGUAGE_CODE: { [key: string]: number } = {
  [GERMAN]: 49,
  [ROMANSH]: 4181,
};

export type TFunction = (key: string, context?: object) => string;

const INITIAL_STATE: {
  currentLanguage: string;
  currentLocale: string;
  currentLanguageCode: number;
  setLanguage: (language: string) => void;
  t: TFunction;
} = {
  currentLanguage: DEFAULT_LANGUAGE,
  currentLocale: LOCALES[DEFAULT_LANGUAGE],
  currentLanguageCode: LANGUAGE_CODE[DEFAULT_LANGUAGE],
  setLanguage: (_language: string) => {},
  t: (key: string, _context?: object) => {
    return key;
  },
};

const TranslationsContext = createContext(INITIAL_STATE);

type Props = {
  children: ReactNode;
  language?: string;
  devMode: boolean;
};
export const TranslationsProvider = ({
  children,
  language,
  devMode,
}: Props) => {
  const [translations, setTranslations] = useState(getTranslations(language));
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    language || DEFAULT_LANGUAGE,
  );
  const [currentLocale, setCurrentLocale] = useState<string>(
    LOCALES[currentLanguage],
  );
  const [currentLanguageCode, setCurrentLanguageCode] = useState<number>(
    LANGUAGE_CODE[currentLanguage],
  );

  const setLanguage = (language: string) => {
    if (Object.prototype.hasOwnProperty.call(languages, language)) {
      setTranslations(getTranslations(language));
      setCurrentLanguage(language);
      setCurrentLocale(LOCALES[language]);
      setCurrentLanguageCode(LANGUAGE_CODE[language]);
      return;
    }

    setTranslations(getTranslations(DEFAULT_LANGUAGE));
    setCurrentLanguage(DEFAULT_LANGUAGE);
    setCurrentLocale(LOCALES[DEFAULT_LANGUAGE]);
    setCurrentLanguageCode(LANGUAGE_CODE[DEFAULT_LANGUAGE]);
  };

  const t = (key: string, context?: object) => {
    const properties = key.split(".");

    let translation = properties.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (prev: any, curr) => prev?.[curr],
      translations,
    );

    if (!translation) {
      if (devMode) {
        // eslint-disable-next-line no-console
        console.warn(
          `Translation not found for ${key} in ${translations.currentLang}`,
        );
      }

      return key;
    }

    if (context) {
      // Replace/interpolate variables in the translation string
      Object.entries(context).forEach(([key, value]) => {
        translation = translation.replace(
          `\${${key}}`,
          value?.toString() ?? "",
        );
      });
    }

    return translation;
  };

  return (
    <TranslationsContext.Provider
      value={{
        setLanguage,
        t,
        currentLanguage,
        currentLocale,
        currentLanguageCode,
      }}>
      {children}
    </TranslationsContext.Provider>
  );
};

export const useTranslations = () => useContext(TranslationsContext);
