import classNames from "classnames";
import { ReactNode } from "react";

type ContentContainerProps = {
  children: ReactNode;
  xOverflowAuto?: boolean;
  className?: string;
};

export const ContentContainer = ({
  children,
  xOverflowAuto = false,
  className,
}: ContentContainerProps) => {
  return (
    <div className={classNames("m-flex m-justify-center", className)}>
      <div
        className={classNames("xl:m-max-w-screen-xl m-max-w-full m-flex-1", {
          "m-overflow-x-auto": xOverflowAuto,
        })}>
        {children}
      </div>
    </div>
  );
};
