import { ForecastPointWeek } from "@MeteoApi/models/ForecastPointWeek";
import { Intervals } from "@Models/Interval";
import { SymbolTexts } from "@Models/SymbolTexts";
import { getValidForecastIntervals } from "@Utils/interval";

export type ActiveDateSource =
  | "user-day-select"
  | "scroll-over-date-boundary"
  | "initial";

export type ActiveDate = {
  value: string; // Format: yyyy-MM-dd
  source: ActiveDateSource;
};

// eslint-disable-next-line no-unused-vars
export type Dispatch = (action: Action) => void;

export type State = {
  activeDate: ActiveDate; // This is an object as we need to sometimes force a re-render, by changing the reference
  symbolTexts: SymbolTexts;
  intervals: Intervals;
  forecastPointWeek?: ForecastPointWeek;
};

type Action =
  | {
      type: "set-symbol-texts";
      data: SymbolTexts;
    }
  | {
      type: "set-forecast-point-week";
      data: ForecastPointWeek;
    }
  | {
      type: "set-active-date";
      data: ActiveDate;
    };

export const INITIAL_CONTEXT: State = {
  activeDate: {
    value: "",
    source: "initial",
  },
  symbolTexts: {},
  forecastPointWeek: undefined,
  intervals: [],
};

export const forecastAppContextReducer = (
  state: State,
  action: Action,
): State => {
  switch (action.type) {
    case "set-symbol-texts":
      return {
        ...state,
        symbolTexts: action.data,
      };
    case "set-forecast-point-week": {
      const forecastPointWeek = action.data;

      return {
        ...state,
        forecastPointWeek,
        intervals: forecastPointWeek
          ? getValidForecastIntervals(forecastPointWeek)
          : [],
      };
    }
    case "set-active-date":
      // If date is in the past, set it to today as we do not have data for past dates
      // also ensure that the date is in the correct format and not earlier than x days in the future
      return {
        ...state,
        activeDate: action.data,
      };
    default:
      return state;
  }
};
