import {
  Dispatch,
  INITIAL_CONTEXT,
  State,
  forecastAppContextReducer,
} from "@Context/ForecastAppContext/forecastAppReducer";
import { enrichErrorsWithApplicationState } from "@Utils/sentry";
import { ReactNode, createContext, useContext, useReducer } from "react";

const ForecastAppContext = createContext<State>(INITIAL_CONTEXT);
const ForecastAppDispatchContext = createContext<Dispatch | undefined>(
  undefined,
);

type Props = {
  children: ReactNode;
  value?: Partial<State>;
};

export const ForecastAppContextProvider = ({ children, value }: Props) => {
  const [context, dispatch] = useReducer(forecastAppContextReducer, {
    ...INITIAL_CONTEXT,
    ...value,
  });

  return (
    <ForecastAppContext.Provider value={context}>
      <ForecastAppDispatchContext.Provider value={dispatch}>
        {children}
      </ForecastAppDispatchContext.Provider>
    </ForecastAppContext.Provider>
  );
};

export const useForecastAppDispatcher = () => {
  const context = useContext(ForecastAppDispatchContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useForecastAppDispatcher must be used within a ForecastAppContextProvider",
    );
  }

  return context;
};

export const useForecastAppContext = () => {
  const state = useContext(ForecastAppContext);
  if (typeof state === "undefined") {
    throw new Error(
      "useForecastAppContext must be used within a ForecastAppContext",
    );
  }

  // Listen globally for state changes and set Sentry Context as a side-effect
  enrichErrorsWithApplicationState(state);

  return state;
};
