import { ForecastPointWeek } from "@MeteoApi/models/ForecastPointWeek";
import { OneHourForecastInterval } from "@MeteoApi/models/OneHourForecastInterval";
import {
  Intervals,
  OneHourInterval,
  ThreeHourInterval,
} from "@Models/Interval";
import { toUtcDate } from "@Utils/date";
import {
  addDays,
  endOfDay,
  isAfter,
  isBefore,
  isSameDay,
  isSameHour,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";

const MAX_NUMBER_OF_DAYS = 8;
const MAX_NUMBER_OF_DAYS_WITH_HOUR_VALUES = 3;

export const toTypedInterval = (
  hour: OneHourForecastInterval | ThreeHourInterval,
  type: "1h" | "3h",
): OneHourInterval | ThreeHourInterval => {
  if (type === "1h") {
    return {
      ...hour,
      type: "1h",
    } as OneHourInterval;
  }

  return {
    ...hour,
    type: "3h",
  } as ThreeHourInterval;
};

export const getIntervalStartIndicesPerDay = (
  intervals: Intervals,
  timezone: string,
): number[] => {
  // Key/Index: Index of the day
  // Value: Index of the first interval of that day
  return intervals
    .map((hour, index) => {
      if (index === 0) {
        return index;
      }

      const currentHour = toZonedTime(toUtcDate(hour?.date_time), timezone);
      const previousHour = toZonedTime(
        toUtcDate(intervals[index - 1]?.date_time),
        timezone,
      );

      const dateToday = isSameDay(currentHour, previousHour);
      return dateToday === false ? index : null;
    })
    .filter((index) => index !== null);
};

export const getValidForecastIntervals = (
  forecastPointWeek: ForecastPointWeek,
): Intervals => {
  const {
    geolocation: { timezone },
    hours = [],
    three_hours = [],
  } = forecastPointWeek;

  // Add tye type property to each interval so we can distinguish them later on when rendering
  const oneHourIntervals = hours.map((hour) => toTypedInterval(hour, "1h"));
  const threeHourIntervals = three_hours.map((threeHour) =>
    toTypedInterval(threeHour, "3h"),
  );

  const now = toUtcDate(new Date().toISOString());
  const zonedNow = toZonedTime(now, timezone);
  const zonedLastHourlyIntervalCutoff = addDays(
    endOfDay(zonedNow),
    MAX_NUMBER_OF_DAYS_WITH_HOUR_VALUES - 1, // Subtract 1 because today is included too
  );

  const zonedLastDay = endOfDay(addDays(zonedNow, MAX_NUMBER_OF_DAYS - 1)); // Subtract 1 because today is included too

  // Only keep the intervals that are in the current hour or in the future,
  // but no more than MAX_NUMBER_OF_DAYS in the future.
  // In the case of hourly intervals, we only keep MAX_NUMBER_OF_DAYS_WITH_HOUR_VALUES days (including today),
  // after which we will switch to displaying three-hour intervals instead.
  const currentOrFutureOneHourIntervals = oneHourIntervals
    .filter((interval) => {
      const intervalDate = toZonedTime(toUtcDate(interval.date_time), timezone);

      return (
        isSameHour(intervalDate, zonedNow) ||
        (isAfter(intervalDate, zonedNow) &&
          isBefore(intervalDate, zonedLastHourlyIntervalCutoff))
      );
    })
    .sort((a, b) => a.date_time.localeCompare(b.date_time));

  // After the one hour intervals, we switch to three-hour intervals for the remaining days
  const remainingThreeHourIntervals = threeHourIntervals
    .filter((interval) => {
      const intervalDate = toZonedTime(toUtcDate(interval.date_time), timezone);

      return (
        isAfter(intervalDate, zonedLastHourlyIntervalCutoff) &&
        isBefore(intervalDate, zonedLastDay)
      );
    })
    .sort((a, b) => a.date_time.localeCompare(b.date_time));

  return [...currentOrFutureOneHourIntervals, ...remainingThreeHourIntervals];
};
